export const airshop_request = {
    "AirShoppingRQ":{
       "Party":{
          "Sender":{
             "TravelAgencySender":{
                "Name":"kathir",
                "IATA_Number":"",
                "AgencyID":"",
                "Contacts":{
                   "Contact":[
                      {
                         "EmailContact":"kathir@gmail.com"
                      }
                   ]
                }
             }
          }
       },
       "CoreQuery":{
          "OriginDestinations":{
             "OriginDestination":[
                {
                    "Departure":{
                      "AirportCode":"MAA",
                      
                    },
                    "Arrival":{
                      "AirportCode":"DXB",
                      "Date": "2021-08-20", //Arrival date based search
                      "ArrivalTime": {
                        "From": "2000",
                        "To": "2330"
                      }
                    },
                    "PlusOnedayOption" : 'N'
                  }
            ]
          }
       },
       "DataLists":{
          "PassengerList":{
             "Passenger":[
                {
                   "PassengerID":"T1",
                   "PTC":"ADT"
                }
             ]
          }
       },
       "Preference":{
          "TripType":"Oneway",
            //"FareType":"BOTH",
            "FareType": [
                "70J",
                "749"
            ], 
            "Cabin":"ECONOMY",  //ALL
            "AdditionalCabinType":"PREMECONOMY,BUSINESS",
            "AlternateDays":0,
            "DirectFlight":"",
            "Refundable":"",
            "NearByAirports":"",
            "FreeBaggage":"N",
            "NoOfStops": "",
            "ConnectionTime": {
            "Min" : " ",
            "Max" : " "
            },
            "NoOfResults": 300,
            "NeedBestItinenery":"N",
            "CountryPreference":{
            "ExcludeCountry":[
                 "US"
            ]
        },
           // "DoubleOneWay":"Y"


      
    },
    "MetaData":{
      "Currency":"CAD",
      "FareGrouping":"deal",
      "ProductType":"F",
      "LocalPccCountry":"IN",
      "TraceId":"162495267992",
      "Tracking":"Y",
      "RecommendedFare":"Y",
      "UpSale":"Y",
      "PortalUserGroup":"G1",
      "ParentAccountId":4
    }
    }
 };
 export const airshop_return_request = {
    "AirShoppingRQ": {
        "Party": {
            "Sender": {
                "TravelAgencySender": {
                    "Name": "kathir",
                    "IATA_Number": "",
                    "AgencyID": "",
                    "Contacts": {
                        "Contact": [
                            {
                                "EmailContact": "kathir@gmail.com"
                            }
                        ]
                    }
                }
            }
        },
        "CoreQuery": {
            "OriginDestinations": {
                "OriginDestination": [
                    {
                        "Departure": {
                            "AirportCode": "MAA",
                            
                        },
                        "Arrival": {
                            "AirportCode": "DXB",
                            "Date": "2021-10-25",
                            "ArrivalTime": {
                                "From": "2000",
                                 "To": "2330"
                           }
                        },
                        "PlusOnedayOption" : 'N'
                    },
                    {
                        "Departure": {
                            "AirportCode": "DXB",
                            
                        },
                        "Arrival": {
                            "AirportCode": "MAA",
                            "Date": "2021-10-28",
                            "ArrivalTime": {
                                "From": "2000",
                                 "To": "2330"
                           }
                        },
                        "PlusOnedayOption" : 'N'
                    }
                ]
            }
        },
        "DataLists": {
      "PassengerList": {
        "Passenger": [
          {
            "PassengerID": "T1",
            "PTC": "ADT"
          }
        ]
      }
    },
    "Preference": {
          "TripType": "Return",
           //"FareType":"BOTH",
           "FareType": [
            "70J",
            "749"
        ], 
            "Cabin":"ECONOMY",  //ALL
            "AdditionalCabinType":"PREMECONOMY,BUSINESS",
            "AlternateDays":0,
            "DirectFlight":"",
            "Refundable":"",
            "NearByAirports":"",
            "FreeBaggage":"N",

      

            "NoOfStops": "",
            "ConnectionTime": {
            "Min" : " ",
            "Max" : " "
            },
            "NoOfResults": 300,
            "NeedBestItinenery":"N",
            "CountryPreference":{
            "ExcludeCountry":[
                 "US"
            ]
        },
            "DoubleOneWay":"N"


      
    },
    "MetaData":{
      "Currency":"CAD",
      "FareGrouping":"deal",
      "ProductType":"F",
      "LocalPccCountry":"IN",
      "TraceId":"162495267992",
      "Tracking":"Y",
      "RecommendedFare":"Y",
      "UpSale":"Y",
      "PortalUserGroup":"G1",
      "ParentAccountId":4
    }
  }   
};
export const airshop_multireturn_request = 
{
    "AirShoppingRQ": {
        "Party": {
            "Sender": {
                "TravelAgencySender": {
                    "Name": "Pandian",
                    "IATA_Number": "",
                    "AgencyID": "",
                    "Contacts": {
                        "Contact": [
                            {
                                "EmailContact": "srilanakamrst@gmail.com"
                            }
                        ]
                    }
                }
            }
        },
        "CoreQuery": {
            "OriginDestinations": {
                "OriginDestination": [
                    {
                        "Departure": {
                            "AirportCode": "MAA",
                            
                        },
                        "Arrival": {
                            "AirportCode": "MCT",
                            "Date": "2021-10-16",
                            "ArrivalTime": {
                                "From": "2000",
                                 "To": "2330"
                           }
                        },
                        "PlusOnedayOption" : 'N'
                    },
                    {
                        "Departure": {
                            "AirportCode": "MCT",
                            
                        },
                        "Arrival": {
                            "AirportCode": "DXB",
                            "Date": "2021-10-20",
                            "ArrivalTime": {
                                "From": "2000",
                                 "To": "2330"
                           }
                        },
                        "PlusOnedayOption" : 'N'
                    },
                    {
                        "Departure": {
                            "AirportCode": "DXB",
                           
                        },
                        "Arrival": {
                            "AirportCode": "MAA",
                            "Date": "2021-10-22",
                            "ArrivalTime": {
                                "From": "2000",
                                 "To": "2330"
                           }
                        },
                        "PlusOnedayOption" : 'N'
                    }
                    
                ]
            }
        },
        "DataLists": {
            "PassengerList": {
                "Passenger": [
                    {
                        "PassengerID": "T1",
                        "PTC": "ADT",
                        "LoyaltyProgramAccount": [
                            {
                                "AirlineID": "EY",
                                "AccountNumber": "8349843875"
                            }
                        ],
                        "Individual": {
                            "Birthdate": "1996-03-15"
                        }
                    }
                ]
            }
        },
        "Qualifier": {
            "SpecialFareQualifiers": [
                {
                    "AirlineID": "XX",
                    "AccountNumber": "SpecialCorporateCode",
                    "PromoCode": ""
                }
            ]
        },
        "Preference": {
            "TripType": "MULTI",
            //"FareType":"BOTH",
            "FareType": [
                "70J",
                "749"
            ], 
            "Cabin":"ECONOMY",  //ALL
            "AdditionalCabinType":"PREMECONOMY,BUSINESS",
            "AlternateDays":0,
            "DirectFlight":"",
            "Refundable":"",
            "NearByAirports":"",
            "FreeBaggage":"N",

      

            "NoOfStops": "",
            "ConnectionTime": {
            "Min" : " ",
            "Max" : " "
            },
            "NoOfResults": 300,
            "NeedBestItinenery":"N",
            "CountryPreference":{
            "ExcludeCountry":[
                 "US"
            ]
        },
           // "DoubleOneWay":"N"


      
    },
    "MetaData":{
      "Currency":"CAD",
      "FareGrouping":"deal",
      "ProductType":"F",
      "LocalPccCountry":"IN",
      "TraceId":"162495267992",
      "Tracking":"Y",
      "RecommendedFare":"Y",
      "UpSale":"Y",
      "PortalUserGroup":"G1",
      "ParentAccountId":4
    }
    }
}
    

 export const airofferprice_req = {
    "OfferPriceRQ": {
        "Document": {
            "Name": "Skyroute B2B Portal",
            "ReferenceVersion": "1.0"
        },
        "Party": {
            "Sender": {
                "TravelAgencySender": {
                    "Name": "Skyroute B2B",
                    "IATA_Number": "1111111111",
                    "AgencyID": "1111111111",
                    "Contacts": {
                        "Contact": [
                            {
                                "EmailContact": "skyroute@gmail.com"
                            }
                        ]
                    }
                }
            }
        },
        "ShoppingResponseId": "1623155041693505117",
        "Query": {
            "Offer": [
                {
                    "OfferID": "29337811623155042481743672"
                }
            ]
        },
        "DataLists": {
            "PassengerList": {
                "Passenger": [
                    {
                        "PassengerID": "T1",
                        "PTC": "ADT",
                        "LoyaltyProgramAccount": [
                            {
                                "AirlineID": "AA",
                                "AccountNumber": "8349843875"
                            }
                        ],
                        "Individual": {
                            "Birthdate": "1996-03-15"
                        }
                    }
                ]
            }
        },
        "MetaData": {
            "TraceId": "161777799749"
        }
    }
};

export const createorder_req = {
    "OrderCreateRQ": {
        "Document": {
            "Name": "kathir",
            "ReferenceVersion": "1.0"
        },
        "Party": {
            "Sender": {
                "TravelAgencySender": {
                    "Name": "kathir",
                    "IATA_Number": "",
                    "AgencyID": "",
                    "Contacts": {
                        "Contact": [
                            {
                                "EmailContact": "kathir@gmail.com"
                            }
                        ]
                    }
                }
            }
        },
        "ShoppingResponseId": "1623155041693505117",
        "OfferResponseId": "1623324733471153277",
        "SeatResponseId": "",
        "ServiceListResponseId": "",
        "MetaData": {
            "Tracking": "Y",
            "PassengerMetadata" : {
                "SpecialServiceRequest" : []
            }
        },
        "Query": {
            "Order": {
                "Offer": [
                    {
                        "OfferID": "29337811623155042481743672"
                    }
                ],
               "ServiceList": [
                    
                ],
                "Seat": [
                    
                ]
            }
        },
        "BookingType": "BOOK",
        "PromoCode": "",
        "PromoDiscount": 0,
        "Payments": {
            "Payment": [
                {
                    "Type": "CARD",
                    "Amount": 166.24,
                   
                    "Method": {
                        "PaymentCard": {
                            "CardType": "CC",
                            "CardCode": "VI",
                            "CardNumber": "4111111111111111",
                            "SeriesCode": "123",
                            "CardHolderName": "Kumar",
                            "EffectiveExpireDate": {
                                "Effective": "",
                                "Expiration": "2022-05"
                            }
                        }
                    },
                    "Payer": {
                        "ContactInfoRefs": "CTC1"
                    }
                }
            ]
        },
        "DataLists": {
            "PassengerList": {
                "Passenger": [
                    {
                        "PassengerID": "ADT1",
                        "PTC": "ADT",
                        "OnflyMarkup": "0.00",
                        "OnflyDiscount": "0.00",
                        "BirthDate": "2000-07-21",
                        "NameTitle": "Mr",
                        "FirstName": "downey",
                        "MiddleName": "",
                        "LastName": "Robert",
                        "Gender": "Male",
                        "TravelDocument": [
                            {
                                "DocumentNumber": "9658745",
                                "DocumentType": "P",
                                "ExpiryDate": "2024-01-01",
                                "IssuingCountry": "CA"
                            }
                        ],
                        "LoyaltyProgramAccount": [
                            {
                                "ProgramID":"AA",
                                "AirlineID": "AA",
                                "AccountNumber": "8349843875"
                            }
                        ],
                        "ContactInfoRef": "CTC1"
                    }
                ]
            },
            "ContactList": {
                "ContactInformation": [
                    {
                        "ContactID": "CTC1",
                        "AgencyName": "kathir",
                        "EmailAddress": "kathir@gmail.com",
                        "Phone": {
                            "ContryCode": "+91",
                            "AreaCode": "",
                            "PhoneNumber": "9854785465"
                        },
                        "Mobile": {
                            "ContryCode": "+91",
                            "MobileNumber": "9854785465"
                        },
                        "Address": {
                            "Street": [
                                "kovil Street",
                                null
                            ],
                            "CityName": "chennai",
                            "StateProv": "AX",
                            "PostalCode": "600028",
                            "CountryCode": "CA"
                        },
                        "PassengerContact": {
                            "EmailAddress": "mosris@gmail.com",
                            "Phone": {
                                "ContryCode": "",
                                "AreaCode": "",
                                "PhoneNumber": "9856325698"
                            }
                        }
                    }
                ]
            }
        }
    }
};

export const retreivebooking_req = {
    "OrderRetreiveRQ": {
        "Document": {
            "Name": "Wintlt NDC GATEWAY",
            "ReferenceVersion": "1.0"
        },
        "Party": {
            "Sender": {
                "TravelAgencySender": {
                    "Name": "Skyroute B2B Portal",
                    "IATA_Number": "1111111111",
                    "AgencyID": "Skyroute B2B Portal",
                    "Contacts": {
                        "Contact": [
                            {
                                "EmailContact": "skyroute@gmail.com"
                            }
                        ]
                    }
                }
            }
        },
        "Query": {
            "OrderID": "VF2EGBN1",
            "GdsBookingReference": ["UPMEFY"]
        }
    }
};

export const createticket_req = {
    "AirDocIssueRQ": {
        "Document": {
            "Name": "Wintlt NDC GATEWAY",
            "ReferenceVersion": "1.0"
        },
        "Party": {
            "Sender": {
                "TravelAgencySender": {
                    "Name": "Skyroute B2B Portal",
                    "IATA_Number": "1111111111",
                    "AgencyID": "Skyroute B2B Portal",
                    "Contacts": {
                        "Contact": [
                            {
                                "EmailContact": "skyroute@gmail.com"
                            }
                        ]
                    }
                }
            }
        },
        "Query": {
            "OrderID": "VF2EGBN1",
            "GdsBookingReference": ["UPMEFY"]
        }
    }
};