/* ============
 * Dutch Language File
 * ============
 *
 * An example language file.
 */

import auth from './auth.json';
import general from './general.json';

export default {
  auth,
  general,
};
