import Vue from 'vue';
import './assets/scss/app.scss';

/* ============
 * Plugins
 * ============
 */
import './plugins/vuex';
import './plugins/axios';
import { i18n } from './plugins/vue-i18n';
import { router } from './plugins/vue-router';
import './plugins/vuex-router-sync';
import './plugins/bootstrap';
import './plugins/font-awesome';
import './plugins/register-service-worker';
import './plugins/validation';
import './plugins/toast';

/* ============
 * Main App
 * ============
 */

import App from './App.vue';
import store from './store';

store.dispatch('bookings/init');

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  el: '#app',
  i18n,
  router,
  store,
  render: (h) => h(App),
});
