import axios from 'axios';
export default {
    namespaced: true,
    state: {
        bapploaderstatus : false,
        jsonmodel : {
            title : "",
            url : "",
            api_req : "",
            api_res : ""
        }
    },
    getters : {
        getAppLoaderstatus(state) {
            return state.bapploaderstatus;
        },
        getJsonModel(state) {
            return state.jsonmodel;
        }
    },
    mutations : {
        setAppLoaderstatus(state, payload) {
            state.bapploaderstatus = payload;
        },
        setJsonModel(state, payload) {
            state.jsonmodel = payload;
        }
    },
    actions : {
         setAppLoaderstatus({ commit}, payload) {
            commit('setAppLoaderstatus', payload)
         },
         setJsonModel({ commit}, payload) {
            commit('setJsonModel', payload)
         },
    }
}